*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

:root {
  --color-white: #fff;
  --color-black: #111;
}

table {
  border-collapse: collapse;
  display: inline-block;
  width: 100%;
}

html {
  font-size: 16px;
}

body {
  background: #111;
  overflow-x: hidden !important;
}

img {
  pointer-events: none;
}

button {
  font-family: 'Oswald', sans-serif;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

ul li,
ol li {
  padding: 0;
  margin: 0;
}
li {
  list-style-image: none;
}
button {
  cursor: pointer;
}

img {
  width: 100%;
}
.main-container {
  width: 100%;
  margin: 0 auto;
}
.main-body {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}
.main-body-section {
  display: flex;
  gap: 20px;
  position: relative;
  margin-left: clamp(
    170px,
    calc(
      220px - (max(0px, 1920px - 100vw) * 0.02) - (max(0px, 1300px - 100vw) * 0.04)
    ),
    220px
  );
  transition: all 0.5s ease;
}
.main-body-content {
  width: calc(100% - 350px);
  max-width: 1300px;
  min-width: 550px;
}
.side-bar-container {
  width: 350px;
  min-width: 260px;
  height: 200px;
  background: black;
  position: relative;
}
.header-nav-container {
  background: #0e0e0e;
  height: 100px;
  margin-bottom: 20px;
}
.header-nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.main-navigation ul a:hover {
  background: #171717;
}
.main-navigation .active {
  border-left: 3px solid #8eb000;
  background: #171717;
}
.header-nav-logo img {
  max-width: 120px;
  cursor: pointer;
  margin-left: 15px;
}
.main-navigation {
  background: #1c1c1c;
  width: clamp(150px, 15%, 200px);
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  transition: all 0.5s ease;
}
.main-navigation ul a {
  display: flex;
  font-size: clamp(10px, 1vw, 14px);
  padding: clamp(10px, 1vw, 15px) 0;
  color: #d0d0d0;
  border-left: 3px solid transparent;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease;
}
.main-navigation ul li {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.main-navigation ul img {
  width: clamp(15px, 1vw, 20px);
  height: clamp(15px, 1vw, 20px);
  margin-right: 10px;
}
.navigation-divider {
  border: solid 1px #8eb000;
  width: 150px;
  margin: 20px auto;
}
.nav-item-download-app {
  color: #8eb000!important;
  font-weight: 700;
}
.header-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.header-nav-btn button {
  max-width: 220px;
  width: 100%;
  padding-inline: 30px;
  border-radius: 5px;
  padding-block: 5px;
  color: var(--color-white);
  border: 1px solid #d0ff52;
  background: linear-gradient(
    to top,
    rgba(103, 147, 0, 1) 0%,
    rgba(145, 176, 0, 1) 100%
  );
  font-size: 1rem;
}
.modal {
  font-size: 12px;
  background: var(--color-white);
  padding-block: 20px;
  padding-inline: 10px;
  max-width: 320px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.footer-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.vip-container {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.vip-container h2,
.vip-container h3 {
  color: var(--color-white);
  font-family: 'Oswald';
  text-transform: uppercase;
  font-weight: 300;
}
.vip-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vip-card img {
  max-width: 130px;
}
.vip-card {
  display: inline-block;
}
.vip-table {
  margin-top: 20px;
  font-family: 'Oswald';
  background-color: #090909;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.vip-table tr td:first-child {
  display: inline-block;
  padding-right: 20px;
}

.vip-table tr:nth-child(odd) {
  background-color: #090909;
  color: var(--color-white);
}
.vip-table tr:nth-child(even) {
  background-color: #dddddd;
}
/* CSS */
.slider-container {
  transition: all 0.5s ease;
  margin-bottom: 10px;
}
.slick-dots li button:before {
  color: #8eb000;
  
}
.slick-dots li.slick-active button:before {
  color: #8eb000;
}
.slick-dots {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: transparent!important;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.slick-prev {
  left: 10px;
}
.slick-next {
  right: 10px;
}


/* Announcement Ticker CSS */
.announcement-ticker {
  display: flex;
  align-items: center;
  background-color: #1b1b1b;
  color: white;
  padding: 10px 20px;
  font-size: clamp(10px, 1vw, 13px);
  overflow: hidden;
  margin-bottom: 20px;
}
.static-label {
  flex-shrink: 0;
  margin-right: 10px;
  display: flex;
}
.static-label img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.dynamic-content {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 20px;
}
.news-item {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}
.news-item.active {
  opacity: 1;
  transform: translateY(0);
}
.news-item.hidden {
  opacity: 0;
  transform: translateY(-20px);
}
.winner-id {
  color: #8eb000;
  font-weight: 700;
}

