@font-face {
  font-family: 'Oswald';
  src: url('/public/font/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

button {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
